import React, {useEffect, useState} from 'react';
import 'react-phone-input-2/lib/style.css';
import {useRouter} from 'next/router';
import {authApi, profileApi} from '@/api/account';
import {setTokenIntoCookies, removeWhiteLabelOtto} from '@/api/common-utils';
import {useTranslation} from 'next-i18next';
import {useLeadCapture} from '@/utils/hooks';
import {updateGTMDatalayer} from '@/utils/gtm';
import {getSingleUrlParam} from '@/utils/url';
import Cookies from 'js-cookie';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import {notification} from '@/utils/notifications';
import {apiError} from '@/utils/api';
import {metaClickEvents} from '@/utils/functions';
import {observer} from 'mobx-react';
import {getCookieWithKey} from '@/api/common-utils';
export interface RegistrationForm {
  email: string;
  contactName: string;
  phoneNumber: string;
  companyName: string;
  website: string;
  category: string;
  budget: string;
  password: string;
}

interface Props {
  isCheckoutPage?: boolean;
  openRegisterPage?: (value) => void;
}

export const RegisterPage: React.FC<Props> = observer(() => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation('common');
  const leadCaptureData = useLeadCapture();
  const utmParams = getCookieWithKey('utm_params');
  const skipEmailVerification = getSingleUrlParam('marketing_register');
  const nameParam = getSingleUrlParam('name');
  const emailParam = getSingleUrlParam('email');

  const [stepData, setStepData] = useState({
    step: 'stepOne',
    data: {email: '', contactName: '', phoneNumber: '', password: ''},
  });

  useEffect(() => {
    const values = {
      contactName: nameParam || '',
      email: emailParam || '',
    };
    if (nameParam && emailParam) {
      setStepData({step: 'stepTwo', data: {...stepData.data, ...values}});
      metaClickEvents('click_register_step_1', {step: 'step 1', email: values?.email, contactName: values?.contactName});
      updateGTMDatalayer({'event': 'email_added', 'step': 'step 1', 'email': values?.email, 'contactName': values?.contactName});
    } else {
      setStepData({step: 'stepOne', data: {...stepData.data, ...values}});
    }
  }, [nameParam, emailParam]);

  const registerSource = getSingleUrlParam('source');
  const referral = getSingleUrlParam('referral') || getSingleUrlParam('fpr') || Cookies.get('_fprom_ref');
  const invitationToken = getSingleUrlParam('invitation_token');
  const partnerValue = Cookies.get('partner');

  const errors = error => {
    return error.join(', ');
  };

  const onBadRequestError = (error: any) => {
    const errorData = error.response.data;
    const errorKeys = [
      'email',
      'password',
      'contactName',
      'phoneNumber',
      'nonFieldErrors',
      'detail',
    ];
    const fieldErrorMessages = [];
    const errorMessageList = [];
    errorKeys.forEach(errorKey => {
      const errors = errorData[errorKey];
      if (errors) {
        const errorMessage = Array.isArray(errors) ? errors[0] : errors;
        if (!['nonFieldErrors', 'detail'].includes(errorKey)) {
          const obj = {};
          obj[errorKey] = {
            validateStatus: 'error',
            errorMsg: errorMessage,
          };
          fieldErrorMessages.push(obj);
        } else {
          errorMessageList.push(errorMessage);
        }
      }
    });
    if (!errorMessageList.length) {
      const errorMessage = apiError(error);
      notification.error(errorMessage, 'topRight');
    } else {
      notification.error(errors(errorMessageList) as string, 'topRight');
    }
  };

  const onRegister = async phoneNumber => {
    const {email, contactName, password} = stepData.data;
    setLoading(true);
    const data = {
      contact_name: contactName,
      phone_number: `+${phoneNumber}`,
      email: email,
      password: password,
      is_code_verification: true,
      onboarding_variation: 'register-a',
      ...(utmParams ? JSON.parse(utmParams) : {}),
      ...(skipEmailVerification == 'true' && {skip_email_verification: true}),
      registration_source: registerSource ?? 'dashboard_main',
      ...leadCaptureData,
    };
    if (referral || partnerValue) {
      data['referral'] = referral ? referral : partnerValue;
    }
    if (invitationToken) {
      data['invitation_token'] = invitationToken;
    }
    try {
      const response = await authApi.register(data);
      removeWhiteLabelOtto();
      if (response.status === 201) {
        setTokenIntoCookies(response.data.jwtToken);
        setLoading(false);
        if ((referral || partnerValue) && (typeof window != 'undefined')) {
          if (window['fpr']) {
            window['fpr']('referral', {email: email});
          }
        }
        metaClickEvents('register', {...data, status: 'success', password: ''});
        updateGTMDatalayer({'event': 'phone_number_added', 'step': 'step 3', 'phone': phoneNumber});
        updateGTMDatalayer({'event': 'account created', 'step': 'step 3', ...data});
        const checkSCAUrl = localStorage.getItem('registerEmailUrl');
        const customerExtendedRes = await profileApi.getProfile();
        if (checkSCAUrl && checkSCAUrl !== '/') {
          router.push(checkSCAUrl);
        } else {
          if (customerExtendedRes?.isWhitelabel) {
            router.push('/onboarding');
          } else {
            router.push('/choose-plan');
          }
        }
      }
    } catch (error) {
      const status = error.response?.status || 200;
      if ( status === 406) {
        notification.error('ProtonMail emails are not allowed for registration.', 'topRight');
        setLoading(false);
        return;
      }
      if (status === 400 || status === 409) {
        onBadRequestError(error);
      } else {
        notification.error(t('went-wrong'), 'topRight');
      }
      metaClickEvents('register', {...data, password: '', statusCode: status, status: 'failed'});
      updateGTMDatalayer({'event': 'registration_process_failed', ...data, 'password': '', 'statusCode': status, 'status': 'failed'});
    }
    setLoading(false);
  };

  const steps = {
    stepOne: {
      component: <StepOne
        setStepData={setStepData}
        stepData={stepData}
      />,
    },
    stepTwo: {
      component: <StepTwo
        setStepData={setStepData}
        stepData={stepData}
      />,
    },
    stepThree: {
      component: <StepThree
        setStepData={setStepData}
        stepData={stepData}
        onRegister={onRegister}
        loading={loading}
      />,
    },
  };


  // LEAVING THIS COMMENTED OUT IN THE CASE WE WANNA GO BACK TO USING THIRD-PARTY COOKIE BASED GOOGLE LOGIN
  // const onGoogleOAuthSuccess = async (
  //   payload: GoogleLoginResponse | GoogleLoginResponseOffline,
  // ) => {
  //   resetErrorMessages();
  //   const response = await authApi.validateGoogleOAuthResponseAndLogin({
  //     ...payload,
  //     lead: leadCaptureData,
  //   });
  //   setTokenIntoCookies(response.data.token);
  //   router.push('/');
  // };

  // const onGoogleOAuthFailure = (error: any) => {
  //   setErrorMessages(error);
  // };

  // const showGoogleButton = [`dashboard.${linkgraphDomain}`, 'dashboard.searchatlas.com'];
  // const getHostName = typeof window !== 'undefined' && location.hostname;

  return (
    <>
      {steps[stepData.step]?.component}
    </>
  );
});
